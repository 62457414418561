<template>
  <footer
    class="@container bg-s-900 relative px-4 py-8 @3xl:pb-24 @3xl:pt-20 @5xl:px-14"
  >
    <div class="max-w-content-container mx-auto">
      <!-- Footer links -->
      <div
        class="text-p-500 flex flex-col gap-y-6 font-semibold @3xl:items-baseline @3xl:justify-between @5xl:flex-row @5xl:font-bold"
      >
        <a href="#">
          <IconBenchmarkLogo />
        </a>

        <div
          class="flex flex-col gap-y-2 text-base @3xl:flex-row @3xl:items-end @3xl:justify-end @3xl:gap-x-8 @3xl:text-lg"
        >
          <NuxtLink
            v-for="(link, index) in links"
            :key="index"
            :to="link.to"
            class="hover:underline"
          >
            {{ link.name }}
          </NuxtLink>
        </div>
      </div>

      <!-- Divisor -->
      <div class="border-p-500 my-4 border-b @3xl:my-8" />

      <div class="flex flex-col gap-3 @3xl:flex-row @3xl:justify-between">
        <div class="flex gap-6">
          <NuxtLink
            v-for="socialLink in socialLinks"
            :key="socialLink.name"
            :href="socialLink.to"
            target="_blank"
            class="text-p-400"
            external
          >
            <Icon :name="socialLink.icon" />
          </NuxtLink>
        </div>

        <div
          class="flex flex-col flex-wrap gap-x-20 gap-y-2 text-xs text-white @3xl:flex-row @3xl:justify-end @3xl:text-sm"
        >
          <div
            class="flex flex-col gap-x-2 gap-y-1 @3xl:flex-row @3xl:items-center"
          >
            <NuxtLink to="/terms" class="flex gap-x-2 items-center">
              <span>Terms & Conditions</span>
              <span class="hidden @3xl:block">|</span>
            </NuxtLink>
            <NuxtLink to="/privacy" class="flex gap-x-2 items-center">
              <span>Privacy Policy</span>
              <span class="hidden @3xl:block">|</span>
            </NuxtLink>
            <NuxtLink to="/cookies"> Cookies </NuxtLink>
          </div>
          <span>© Benchmark Mineral Intelligence </span>
        </div>
      </div>
      <client-only>
        <CookieConsentBanner />
      </client-only>
    </div>
  </footer>
</template>

<script setup lang="ts">
import CookieConsentBanner from '@/components/CookieConsentBanner.vue'
import IconBenchmarkLogo from '@/assets/icons/BenchmarkLogo.svg'
import Icon from '@/components/common/Icon.vue'

const links = [
  { name: 'Join Team Benchmark', to: '/join-us' },
  { name: 'About Us', to: '/about' },
  { name: 'Newsletter', to: '/newsletter-signup' },
  { name: 'Announcements', to: '/announcements' },
]

const socialLinks = [
  { icon: 'TwitterLogo', name: 'X', to: 'https://twitter.com/benchmarkmin' },
  {
    icon: 'Instagram',
    name: 'Instagram',
    to: 'https://www.instagram.com/benchmarkminerals/',
  },
  {
    icon: 'LinkedIn',
    name: 'LinkedIn',
    to: 'https://www.linkedin.com/company/benchmark-mineral-intelligence/',
  },
]
</script>
